define(
	'views/slides/feedViews/weather',[
		"jquery",
		"lodash",
		"backbone",
		"slick",
		"text!templates/feedsTpl/weather.html",
		"app",
	], function($, _, Backbone, Slick, tmpl, app) {
	
		var View = Backbone.View.extend({
			tagName:'li',

		  attributes: {'class':'feedsTpl'},

			initialize : function(data){

				this.slide = data;
				this.slide.futureForecast = app.forecast;
				this.attributes = {"data-interval":data.duration, "data-template":'data.template', "data-type":'data.data.type'};
				this.$el.attr(this.attributes);

			},
			
			render : function(){
				var self = this;
				if(self.slide.futureForecast) {
            var forecastList = self.slide.futureForecast;
            var forecastList = _(forecastList).filter(function(item) {
                 return item.title.indexOf('Night') === -1;
            });
            self.slide.futureForecast = forecastList;
          }
				var html = _.template(tmpl, {slide: self.slide, weatherIcons: app.weatherIcons, get12HClockTime: app.get12HClockTime});

	        self.$el.html(html).addClass('weatherSlide');;

          setTimeout(function(){

            // $("#slick").slick({
            //     dots: false,
            //     autoplay: true,
            //     speed: 500,
            //     slidesToShow: 6,
            //     arrows: false,
            // });

            $("#slickVertical").slick({
                  dots: false,
                  autoplay: true,
                  speed: 500,
                  slidesToShow: 4,
                  arrows: false,
                  vertical:true
              });

            setTimeout(function(){
	        		$("#slick, #slickVertical").parent().fadeIn();
            }, 1000);
          },100);

		    return this;
			}

		});
		
		return View;
	}
);
	

