define('collections/dataCollection',["jquery", "backbone", "app", "models/dataModel"], function (
  $,
  Backbone,
  app,
  dataModel
) {
  // Creates a new Backbone Collection class object
  var dataCollection = Backbone.Collection.extend({
    // Tells the Backbone Collection that all of it's models will be of type Model (listed up top as a dependency)
    model: dataModel,
    initialize: function (options) {
      var device_id =
        window.metadata && window.metadata.device_id
          ? window.metadata.device_id
          : 0;

      this.url =
        app.API_URL + "v1/signage/signage-device/?deviceId=72901117-e480-4f21-8e71-c6eba91d748f";
    },
  });

  // Returns the Model class
  return dataCollection;
});

