define(
	'views/slides/feedViews/cautinaryWeather',[
		"jquery",
		"lodash",
		"backbone",
		"text!templates/feedsTpl/cautinaryWeather.html",
		"app",
	], function($, _, Backbone, tmpl, app) {
	
		var View = Backbone.View.extend({
			tagName:'li',
			// className : 'cautinaryWeather',

		  attributes: {'class':'feedsTpl'},

			initialize : function(data, weather){
				this.slide = data;
				this.weather = weather;
				this.attributes = {"data-interval":data.duration, "data-template":data.template, "data-type":data.data.type};
				this.$el.attr(this.attributes);
			},
			
			render : function(){
				var self = this;
					var html = _.template(tmpl, {data: self.slide.data, slide: self.weather, weatherIcons: app.weatherIcons});
				
	        self.$el.html(html).addClass('cautinaryWeatherSlide');


		    return this;
			}

		});
		
		return View;
	}
);
	

