define(
	'views/deviceSetup',[
		"jquery",
		"backbone",
		"underscore",
		"app",
		"dataCaching",
		"text!templates/deviceSetup.html",
	], function($, Backbone, _, app, caching, tmpl) {
  		
		
		var View = Backbone.View.extend({
			id: 'deviceSetup',
		
			initialize : function(){
				this.api = new Api();
			},
		
			render : function(){

				var self = this;

				this.api.jqmAjax('GetScreenList',{},function(result){
					console.log(result);
					if(result.success){

						self.devices = result.data;
		    		var html = _.template(tmpl, { data : result.data});
		        self.$el.html(html);
		        
		        return self;
					}
				});

			},

			events: {
				'click button#formBoxBtn' : 'saveDevice'
			},

			saveDevice : function(event){
					

					var device_id = $('input[name=locationRadioField]:checked').val();
					if(device_id){

						app.loader('show');

						var name = $('input[name=locationRadioField]:checked').data('title');
						var result = _.where(this.devices, {device_id: device_id});

						localStorage.device_id = device_id;
						localStorage.location_info  = JSON.stringify(result);
						
						caching.checkUpdates(device_id, function(result){
							if(result.success){
								app.showPage(['views/slider']);
							}						
						});
					}else{
						alert('Please select a device!');
					}
			}
			
		});
		
		return View;
	}
);



