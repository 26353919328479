define('collections/mxpFleetCollection',["jquery", "backbone", "app"], function ($, Backbone, app) {
  var fleetModel = Backbone.Model.extend();

  // Creates a new Backbone Collection class object
  var fleetCollection = Backbone.Collection.extend({
    // Tells the Backbone Collection that all of it's models will be of type Model (listed up top as a dependency)
    model: fleetModel,
    url: app.API_URL + "v1/property-management/fleet/",
  });

  // Returns the Model class
  return fleetCollection;
});

