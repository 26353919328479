define('views/sliderController',[
  "jquery",
  "lodash",
  "backbone",
  "jplayer",
  "jsonPath",
  "dataCaching",
  "app",
  "text!templates/slider.html",
  "superslides",
  "views/partials/header",
  "views/partials/footer",
  "views/slides/image",
  "views/slides/video",
  "views/slides/weather",
  "views/slides/feedViews/explorersLounge",
  "views/slides/feedViews/futureCruise",
  "views/slides/feedViews/medicalCenter",
  "views/slides/feedViews/cautinaryWeather",
  "views/slides/feedViews/shipEntrance",
  "views/slides/feedViews/tenderEntryExit",
  "views/slides/feedViews/torshavnLounge",
  "views/slides/feedViews/worldCafe",
  "views/slides/feedViews/weather",
  "views/slides/feedViews/elevatorA",
  "views/slides/feedViews/elevatorB",
  "views/slides/feedViews/movies",
  "views/slides/feedViews/dining",
  "views/slides/feedViews/spaServices",
  "views/slides/feedViews/spaStaff",
  "views/slides/feedViews/spaTreatments",
  "views/slides/feedViews/salon",
  "views/slides/feedViews/theater",
  "views/slides/feedViews/centeredImage",
  "views/slides/feedViews/staffMembers",
  "views/slides/feedViews/restaurantMenu",
  "views/slides/feedViews/restaurantPromo",
  "views/slides/feedViews/starTheater",
  "views/slides/feedViews/portScreen",
  "config",
], function (
  $,
  _,
  Backbone,
  jPlayer,
  JSONPath,
  caching,
  app,
  tmpl,
  Superslides,
  HeaderView,
  FooterView,
  ImageSlide,
  VideoSlide,
  WeatherSlide,
  ExplorersLounge,
  FutureCruise,
  MedicalCenter,
  CautinaryWeather,
  ShipEntrance,
  TenderEntryExit,
  TorshavnLounge,
  WorldCafe,
  Weather,
  ElevatorA,
  ElevatorB,
  Movies,
  Dining,
  SpaServices,
  SpaStaff,
  SpaTreatments,
  Salon,
  Theater,
  CenteredImage,
  StaffMembers,
  RestoMenu,
  RestoPromo,
  StarTheater,
  PortScreen
) {
  var View = Backbone.View.extend({
    id: "slides",

    initialize: function () {
      var self = this;
      this.slider = null;
      // this.weather = new WeatherSlide();
      // this.weatherv2 = new WeatherSlide({type: "v2"});
      this.fonts_loaded = false;
      app.loader("hide");

      //check for updates on timed basis
      setTimeout(function () {
        setInterval(function () {
          app.updating = true;
          app.slider = self;
          caching.checkUpdates(app.localStorage.device_id, function (response) {
            if (app.data.slides) {
              if (response.updated) {
                location.reload();
              } else if (
                app.data &&
                typeof app.data.weather != "undefined" &&
                app.data.weather &&
                app.data.weather.current_observation
              ) {
                var weatherData = app.data.weather.current_observation;
                app.localStorage.weatherData = JSON.stringify(app.data.weather);
                // console.log(weatherData);
                window.display_temp_f = "";
                window.display_temp_c = "";
                if (
                  weatherData &&
                  typeof weatherData.temp_f != "undefined" &&
                  !isNaN(weatherData.temp_f)
                ) {
                  $("div.header li h1.weatherTemp").css(
                    "visibility",
                    "visible"
                  );
                  $("div.header li span.feelsLike").css(
                    "visibility",
                    "visible"
                  );
                  window.display_temp_f = Math.round(weatherData.temp_f);
                  window.display_temp_c = Math.round(
                    (window.display_temp_f - 32) * (5 / 9)
                  );
                } else if (
                  typeof window.metadata != "undefined" &&
                  window.metadata &&
                  typeof window.metadata.Deck != "undefined" &&
                  window.metadata.Deck
                ) {
                  $("div.header li h1.weatherTemp").css(
                    "visibility",
                    "visible"
                  );
                  $("div.header li h1.weatherTemp").html(window.metadata.Deck);
                  if (
                    typeof window.metadata.Location != "undefined" &&
                    window.metadata.Location
                  ) {
                    $("div.header li span.feelsLike").css(
                      "visibility",
                      "visible"
                    );
                    $("div.header li span.feelsLike").html(
                      window.metadata.Location
                    );
                  } else {
                    $("div.header li span.feelsLike").css(
                      "visibility",
                      "hidden"
                    );
                  }
                } else {
                  $("div.header li h1.weatherTemp").css("visibility", "hidden");
                  $("div.header li span.feelsLike").css("visibility", "hidden");
                }
              }
            } else {
              app.startApp();
            }
          });
        }, 300000); //default 300000
      }, 300000); //default 300000
    },

    render: function () {
      //call get slides
      var html = _.template(tmpl);
      this.$el.html(html);
      return this;
    },

    replaceUrl: function (dataObject, assets) {
      var string = JSON.stringify(dataObject);

      _.each(assets, function (asset, i) {
        if (typeof asset === "object") return;
        // console.log(asset);
        var localFile = app.getLocalPath(asset);

       // if (app.fileExists(localFile)) {
          /*console.log(asset);
						 console.log(localFile);*/
          string = string.replace(asset, localFile);
       // }
      });

      var objectData = JSON.parse(string);
      return objectData;
    },

    loaded: function (update) {
      var self = this;

      if (update) app.loader("show");

      $("ul.slides-container").empty();
      // console.log(app.data);

      var images = jsonPath.eval(app.data.slides, "$..image");
      var assets = jsonPath.eval(app.data.slides, "$..asset");
      var media_url = jsonPath.eval(app.data.slides, "$..media_url");
      var icons = jsonPath.eval(app.data.weather, "$..icon_url");

      if (images) app.data.slides = this.replaceUrl(app.data.slides, images);
      if (assets) app.data.slides = this.replaceUrl(app.data.slides, assets);
      if (icons) app.data.weather = this.replaceUrl(app.data.weather, icons);
      if (media_url)
        app.data.slides = this.replaceUrl(app.data.slides, media_url);

      if (!app.data.weather) app.data.weather = false;
      var headerFooter = {};
      if (
        app.data.settings &&
        app.data.settings.header &&
        app.data.settings.footer
      ) {
        $("body").removeClass("noheader");
        headerFooter = {
          header: new HeaderView(window.metadata, app.data.weather),
          footer: new FooterView(),
        };
      } else if (app.data.settings.header) {
        $("body").addClass("noheader");
        headerFooter = {
          header: new HeaderView(window.metadata, app.data.weather),
        };
      } else if (app.data.settings.footer) {
        $("body").addClass("noheader");
        headerFooter = { footer: new FooterView() };
      }

      // console.log(app.data.slides);

      _.each(app.data.slides, function (slide, i) {
        slide.id = i;
        // console.log('slide count is '+i);
        if (slide.data && slide.data["current_observation"]) {
          // var weatherInterval = setInterval(function(){
          if (app.data.weather.current_observation) {
            self.weather = new Weather(app.data.weather);
            $("ul.slides-container").append(self.weather.render().el);
            app.assign(headerFooter);

            var weatherInterval = setInterval(function () {
              self.weather2 = new Weather(app.data.weather);
              $(
                "ul.slides-container li.weatherSlide div.container.weatherTpl"
              ).html(
                self.weather2
                  .render()
                  .$el.find("div.container.weatherTpl")
                  .html()
              );
            }, 300000);
          }
        } else {
          //console.log(slide);

          // if(!slide.data.data && !slide.data.type) return true;

          //var template = (slide.data && slide.data.type == 'json') ? slide.data.data.template : slide.data.type; //orig
          var template = slide.template;

          if (!template) return true;

          switch (template) {
            case "restaurant_promo":
              self.restoPromo = new RestoPromo(slide);
              $("ul.slides-container").append(self.restoPromo.render().el);

              app.assign(headerFooter);

              break;

            case "Restaurant Menu":
              restaurantOnSlide(slide);
              app.localStorage.mealFromTime = slide.data.selectedMealTime;
              var totalItemsDisplay = slide.data.totalItems;
              if (Math.ceil(slide.data.totalItems / 24) > 1) {
                totalItemsDisplay += Math.ceil(slide.data.totalItems / 24) - 1;
              }
              var numPages = Math.ceil(totalItemsDisplay / 24);
              slide.data.numPages = numPages;
              for (var ipage = 0; ipage < numPages; ipage++) {
                slide.data.currentPage = ipage + 1;
                self.restoMenu = new RestoMenu(slide);
                $("ul.slides-container").append(self.restoMenu.render().el);
                app.assign(headerFooter);
              }

              var restaurantMenuInterval = setInterval(function () {
                restaurantOnSlide(slide);
                if (
                  app.localStorage.mealFromTime != slide.data.selectedMealTime
                ) {
                  location.reload();
                }
              }, 600000);

              break;

            case "Theater":
              self.starTheater = new StarTheater(slide);
              $("ul.slides-container").append(self.starTheater.render().el);

              app.assign(headerFooter);

              var starTheaterInterval = setInterval(function () {
                self.starTheater2 = new StarTheater(slide);
                $(
                  "ul.slides-container li.starTheaterSlide div.container.star-theater-body"
                ).html(
                  self.starTheater2
                    .render()
                    .$el.find("div.container.star-theater-body")
                    .html()
                );
              }, 600000);

              break;

            case "Image Asset":
              //If header or footer, use centered image template
              if (app.data.settings.header || app.data.settings.footer) {
                self.centeredImage = new CenteredImage(slide);
                $("ul.slides-container").append(self.centeredImage.render().el);

                app.assign(headerFooter);
              } else {
                self.imageSlide = new ImageSlide(slide);
                $("ul.slides-container").append(self.imageSlide.render().el);
              }

              break;

            case "theater":
              self.theater = new Theater(slide);
              $("ul.slides-container").append(self.theater.render().el);

              app.assign(headerFooter);

              break;

            case "Promo":
              self.salon = new Salon(slide);
              $("ul.slides-container").append(self.salon.render().el);

              app.assign(headerFooter);

              break;

            case "spa_services":
              self.spaServices = new SpaServices(slide);
              $("ul.slides-container").append(self.spaServices.render().el);

              app.assign(headerFooter);

              break;

            case "spa_staff":
              self.spaStaff = new SpaStaff(slide);
              $("ul.slides-container").append(self.spaStaff.render().el);

              app.assign(headerFooter);

              break;

            case "spa_treatments":
              self.spaTreatments = new SpaTreatments(slide);
              $("ul.slides-container").append(self.spaTreatments.render().el);

              app.assign(headerFooter);

              break;

            case "Cautionary Weather":
              self.cautinaryWeather = new CautinaryWeather(
                slide,
                app.data.weather
              );
              $("ul.slides-container").append(
                self.cautinaryWeather.render().el
              );

              app.assign(headerFooter);

              var cautionaryweatherInterval = setInterval(function () {
                self.cautinaryWeather2 = new CautinaryWeather(
                  slide,
                  app.data.weather
                );
                $(
                  "ul.slides-container li.cautinaryWeatherSlide div.container.cautionary"
                ).html(
                  self.cautinaryWeather2
                    .render()
                    .$el.find("div.container.cautionary")
                    .html()
                );
                $(
                  "ul.slides-container li.cautinaryWeatherSlide div.secondary-msg"
                ).html(
                  self.cautinaryWeather2
                    .render()
                    .$el.find("div.secondary-msg")
                    .html()
                );
              }, 300000);
              break;

            case "portscreen":
              self.portInfo = new PortScreen(slide);
              $("ul.slides-container").append(self.portInfo.render().el);

              app.assign(headerFooter);

              break;

            case "movies":
              self.movies = new Movies(slide);
              $("ul.slides-container").append(self.movies.render().el);
              app.assign(headerFooter);

              break;

            case "elevator_b":
              self.elevatorb = new ElevatorB(slide);
              $("ul.slides-container").append(self.elevatorb.render().el);
              app.assign(headerFooter);

              break;

            case "elevator_a":
              self.elevatora = new ElevatorA(slide);
              $("ul.slides-container").append(self.elevatora.render().el);
              app.assign(headerFooter);

              break;

            case "arrival":
              self.tenderEntryExit = new TenderEntryExit(slide);
              $("ul.slides-container").append(self.tenderEntryExit.render().el);

              app.assign(headerFooter);

              break;

            case "ship_entrance":
              self.shipEntrance = new ShipEntrance(slide);
              $("ul.slides-container").append(self.shipEntrance.render().el);

              app.assign(headerFooter);

              break;

            case "medical":
              self.medicalCenter = new MedicalCenter(slide);
              $("ul.slides-container").append(self.medicalCenter.render().el);

              app.assign(headerFooter);

              break;

            case "future_cruise":
              self.futureCruise = new FutureCruise(slide);
              $("ul.slides-container").append(self.futureCruise.render().el);

              app.assign(headerFooter);

              break;

            case "dining":
              self.dining = new Dining(slide);
              $("ul.slides-container").append(self.dining.render().el);

              app.assign(headerFooter);

              break;

            case "Torshvn":
              self.torshavnLounge = new TorshavnLounge(slide);
              $("ul.slides-container").append(self.torshavnLounge.render().el);
              // if(slide.footer)
              app.assign(headerFooter);

              break;

            case "Video Asset":
              self.videoSlide = new VideoSlide(slide);
              $("ul.slides-container").append(self.videoSlide.render().el);

              var path = slide.data.asset;

              $("#jp_container_" + i).jPlayer({
                ready: function () {
                  $(this).jPlayer("setMedia", {
                    title: "Big Buck Bunny",
                    m4v: path,
                    // m4v: "/rotary_aquaspeed_video_rt_0.30.m4v",
                  });
                },
                ended: function () {
                  $("#slides").superslides("animate");
                },
                swfPath: "../js/jplayer",
                supplied: "m4v",
                fullWindow: true,
                fullScreen: true,
              });

              break;

            case "Staff Screen":
              self.staff = new StaffMembers(slide);
              $("ul.slides-container").append(self.staff.render().el);
              app.getCollectionData("mxpFleet", 900, "", "", function (
                fleetData
              ) {
                var shipInfo = {
                  data: fleetData,
                };

                var shipName = "Your Ship";
                if (
                  typeof shipInfo != "undefined" &&
                  shipInfo &&
                  typeof shipInfo.data != "undefined" &&
                  shipInfo.data &&
                  typeof shipInfo.data[0] != "undefined" &&
                  typeof shipInfo.data[0].ship_name != "undefined"
                ) {
                  shipName = shipInfo.data[0].ship_name;
                }
                $("#ship_name_staff").html(shipName);
                $("#ship_name_staff").css("visibility", "visible");
              });

              app.assign(headerFooter);

              break;
          }
        } //end check if weather template or not
      });

      self.showScreenSaver = false;
      if (typeof self.screenSaverInterval != "undefined") {
        clearInterval(self.screenSaverInterval);
      }
      self.screenSaverClose(false);
      if (update) {
        self.slider = $("#slides");
        self.slider.superslides("update");
        app.loader("hide");

        /*				var currentSlide = $('#slides').superslides('next');
					 $('#slides').clone().addClass('slides-copy').appendTo( "div#main" );
					 self.slider.superslides('update');
					 self.slider.on('animated.slides', function(){
					 console.log('done animating');
					 $('div.slides-copy').fadeOut('slow').remove();
					 });*/
      } else {
        this.initializeSlide();
      }
    },

    initializeSlide: function () {
      var self = this;

      this.slider = $("#slides");
      this.slider.superslides();
      this.slider.superslides("start");

      /**
       * unbind keyup event added by superslides library
       */
      $(document).unbind("keyup");

      //listen to every end of animation and apply timeout on based on slide interval setting
      $(document).on("animated.slides", function () {
        var currentSlide = self.slider.superslides("current");
        var $this = $(".slides-container").find("> li").eq(currentSlide);
        var interval = $this.data("interval") || 10;
        var type = $this.data("type");
        var template = $this.data("template");
        // console.log(type);

        if (type === "weather") {
          setTimeout(function () {
            //$($this).show();
            $($this).find("#video").addClass("video-afterload");
          }, 0);
        } else if (type == "video") {
          $this.find(".jp-video").jPlayer("play");
          // $this.find('button.jp-video-play-icon').trigger('click');
        } else {
          $($this).find(".h-contents").hide();

          if (self.fonts_loaded) {
            $($this).find(".h-contents").fadeIn("slow");
            self.sliderTextPositions($this);
          }

          //position elements after all fonts are loaded
          /*var fonts = $.Deferred();
						 WebFontConfig = { google: { families: [ 'Open+Sans:400,300,600' ] } , active : function() { fonts.resolve(); } };
						 (function() {
						 var wf = document.createElement('script');
						 wf.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
						 wf.type = 'text/javascript';
						 wf.async = 'true';
						 var s = document.getElementsByTagName('script')[0];
						 s.parentNode.insertBefore(wf, s);
						 })();
						 fonts.done(function(){
						 self.fonts_loaded = true;
						 $($this).find('.title,.description').fadeIn();
						 self.sliderTextPositions($this);
						 });*/
        }
        if (type != "video") {
          if (app.data.slides.length > 1) {
            setTimeout(function () {
              self.slider.superslides("start");
            }, interval * 1000);
          } else {
            self.showScreenSaver = true;
            self.screenSaverTimer();
          }
        }
      });

      $(document).on("animating.slides", function () {
        var currentSlide = self.slider.superslides("current");
        var $this = $(".slides-container li").eq(currentSlide);
        var interval = $this.data("interval");
        var type = $this.data("type");

        if (type === "weather") {
          setTimeout(function () {
            //$($this).show();
            $($this).find("#video").removeClass("video-afterload");
          }, 0);
        }
      });

      //       setTimeout(function() {
      //       $('#slider1').empty().append('<li><img src="http://ppcdn.500px.org/80495611/7c6aafa78e362e33c23be0a28c3b4d8ad0126b6a/2048.jpg" alt=""> <div class="container"> slide sample </div></li>');
      // 	console.log('updated');
      // }, 3000);

      // var i =0;
      //   setInterval(function(){
      //   	$('#slides .slides-container').append('<li><img src="http://ppcdn.500px.org/80495611/7c6aafa78e362e33c23be0a28c3b4d8ad0126b6a/2048.jpg" alt=""> <div class="container"> slide sample '+i+'</div></li>');
      // $('#slides').superslides('update');
      // console.log('updated');

      // i++;
      //   }, 4000);
    },

    screenSaverTimer: function () {
      var self = this;
      if (self.showScreenSaver) {
        self.screenSaverInterval = setInterval(function () {
          var time = new Date();
          var min = time.getMinutes();
          var sec = time.getSeconds();
          if (min % 20 == 0 && sec < 8 && window.showScreenSaver) {
            clearInterval(self.screenSaverInterval);
            self.screenSaverShow();
          }
          min = null;
          time = null;
          sec = null;
        }, 500);
      }
    },

    screenSaverShow: function () {
      var self = this;
      $("#screen_saver_div").css({ opacity: 1 });
      self.screenSaverTimeout = setTimeout(function () {
        self.screenSaverClose(true);
      }, 20000);
    },

    screenSaverClose: function (restart) {
      var self = this;
      if (typeof self.screenSaverTimeout != "undefined") {
        clearTimeout(self.screenSaverTimeout);
      }
      $("#screen_saver_div").css({ opacity: 0 });
      if (restart) {
        self.screenSaverTimer();
      }
    },

    sliderTextPositions: function ($this) {
      if ($($this).hasClass("Template1")) {
        var desch = $($this).find(".title").outerHeight();
        var b = $($this).find(".departure-time-wrapper").outerHeight();
        var b2 = $($this).find(".departure-time-wrapper").outerWidth();
        var offset = $($this).find(".description").offset();
        var off1 = offset.top - desch;
        var off2 = offset.top - (b + desch);

        $($this).find(".weather-box").hide();
        $($this)
          .find(".title")
          .attr("style", "top:" + off1 + "px;");
        $($this)
          .find(".departure-time-wrapper, .departure-time-wrapper-background")
          .attr("style", "top:" + off2 + "px;");
        $($this).find(".departure-time-wrapper-background").height(b).width(b2);
      }

      if ($($this).hasClass("Template2")) {
        var desch = $($this).find(".title").outerHeight();
        var description_h = $($this).find(".description").outerHeight();
        var weather_h = $($this).find(".weather-box").outerHeight();

        var offset = $($this).find(".description").offset();
        var off1 = offset.top - desch,
          off2 = offset.top + description_h;

        $($this)
          .find(".title, .title-background")
          .attr("style", "top:" + off1 + "px;");
        $($this)
          .find(".weather-box")
          .attr("style", "top:" + off2 + "px;");
        $($this)
          .find(".departure-time-wrapper")
          .attr("style", "top:" + (off2 + weather_h) + "px;");
        $($this)
          .find(".weather-box div.cc")
          .attr(
            "style",
            "padding:0 " + $(document).outerWidth() * 0.05 + "px;"
          );
        $($this).find(".title-background").height(desch);
      }
    },

    appendWeatherSlide: function () {
      this.weather.render();
      console.log(this.weather.el);
      $(".slides-container").append(this.weather.el);
    },

    appendWeatherSlideV2: function () {
      this.weatherv2.render();
      console.log(this.weatherv2.el);
      $(".slides-container").append(this.weatherv2.el);
    },
  });

  return View;
});

