define('views/partials/footer',[
  'jquery',
  'underscore',
  'backbone',
  'text!templates/partials/footer.html'
], function($,_, Backbone, tmpl){

  var FooterView = Backbone.View.extend({

    render: function(){
      
      var tpl = _.template(tmpl);

      this.$el.html(tpl);
      
      return this;
    }

  });

  return FooterView;
  
});

