define(
    'views/slides/feedViews/starTheater',[
        "jquery",
        "lodash",
        "backbone",
        "text!templates/feedsTpl/starTheater.html",
        "app"
    ], function($, _, Backbone, tmpl, app) {

        var View = Backbone.View.extend({
            tagName:'li',

            attributes: {'class':'feedsTpl'},

            initialize : function(data){
                this.slide = data;
                this.attributes = {"data-interval":data.duration, "data-template":data.template, "data-type":data.data.type};
                this.$el.attr(this.attributes);

            },

            render : function(){
                var self = this;

                var events = {};
				var today_midnight = getDayMidnightUTCfromTime(getNowVar());
				var today_end = getDayMidnightUTCfromTime(getNowVar());
				today_end.setUTCDate(today_end.getUTCDate() + 1);
                today_end.setUTCSeconds(today_end.getUTCSeconds() - 1);
				var dow = self.getDayOfTheWeek(today_midnight);
                    _.each(self.slide.data, function(event) {
						var event_time = timeAPIFormat(event.start_time,'object');
					   if(event_time >= today_midnight && event_time <= today_end) {
						   event.display_time = timeAPIFormat(event.start_time,'twelvehrtime');
						   if(typeof events[dow] == 'undefined') {
							   events[dow] = [];
						   }
						   events[dow].push(event);
					   }
                    });
                //});
                var html = _.template(tmpl, {slide: self.slide.data.data, count: _.size(events), events: events});
                // console.log(self.slide.data.data);

                self.$el.html(html).addClass('starTheaterSlide');

                return this;
            },
			
		getDayOfTheWeek: function(d) {
          var weekday = new Array(7);
          weekday[0] = "Sunday";
          weekday[1] = "Monday";
          weekday[2] = "Tuesday";
          weekday[3] = "Wednesday";
          weekday[4] = "Thursday";
          weekday[5] = "Friday";
          weekday[6] = "Saturday";
      
          return weekday[d.getUTCDay()];
        }

        });

        return View;
    }
);


