define(
    'views/slides/feedViews/movies',[
        "jquery",
        "lodash",
        "backbone",
        "text!templates/feedsTpl/movies.html",
        "app",
    ], function($, _, Backbone, tmpl, app) {

        var View = Backbone.View.extend({
            tagName:'li',

            attributes: {'class':'feedsTpl'},

            initialize : function(data){
                this.slide = data;
                this.attributes = {"data-interval":data.duration, "data-template":data.template, "data-type":data.data.type};
                this.$el.attr(this.attributes);

            },

            render : function(){
                var self = this;
                var html = _.template(tmpl, {slide: self.slide.data.data});
                // console.log(self.slide.data.data);
                self.$el.html(html);

                return this;
            }

        });

        return View;
    }
);


