define(
	'views/slides/feedViews/staffMembers',[
		"jquery",
		"lodash",
		"backbone",
		"text!templates/feedsTpl/staffMembers.html",
		"app",
	], function($, _, Backbone, tmpl, app) {
	
		var View = Backbone.View.extend({
			tagName:'li',

		  attributes: {'class':'feedsTpl staffMember'},

			initialize : function(data){
				this.slide = data;
				this.attributes = {"data-interval":data.duration, "data-template":data.template, "data-type":data.data.type};
				this.$el.attr(this.attributes);

			},
			
			
			render : function(){
				var self = this;
				  var staffs = {};
				  
				  
				  if(self.slide.data) {
					
				    _.each(self.slide.data, function(staff) {
					
					var order = staff.order;

  				    
					if(staff.media_url) {
						staff.media_url = replaceImages(staff.media_url,450)
					}
					
					staffs['o'+order] = staff;
					
					});
					
				  }
				  
					var html = _.template(tmpl, { staff: staffs });

	        self.$el.html(html);

		    return this;
			}

		});
		
		return View;
	}
);
	

