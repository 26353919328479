
var API, DEVICE_ID;
var WEATHER_SLIDE_ADDED = false;
var ASSET_DIR = '/images/';

require.config({
  paths: {
    "underscore": "../bower_components/lodash/dist/lodash.underscore",
    "lodash": "../vendor/bower/lodash/dist/lodash",
    "text": "../bower_components/text/text",
    "jquery": "../bower_components/jquery/dist/jquery",
    "backbone": "../bower_components/backbone/backbone",
    "animateEnhanced": "../bower_components/animate-enhance/scripts/src/jquery.animate-enhanced",
    "superslides": "../bower_components/superslides/dist/jquery.superslides",
    "jplayer": "../bower_components/jplayer/dist/jplayer/jquery.jplayer.min",
    "jsonPath": "../bower_components/JSONPath/lib/jsonpath",
    "slick": "../bower_components/slick.js/slick/slick.min",
    "utility": "utils", 

  },
  // Define a new object literal, named map.
   map: {
    // Ensure the mapping works globally across any modules using this plugin.
    "*": {
      // Map the lodash identifier to whatever module you want here.
      "lodash": "underscore"
    }
  },

  deps: ["main"],

  shim: {
  	// This is required to ensure Backbone works as expected within the AMD
  	// environment.
  	"backbone": {
  	  // These are the two hard dependencies that will be loaded first.
  	  deps: ["jquery", "underscore"],

  	  // This maps the global `Backbone` object to `require("backbone")`.
  	  exports: "Backbone"
  	},

    "slick" : ["jquery"],

    "animateEnhanced" :["jquery"],

    "superslides" :["jquery", "animateEnhanced"],

    "jplayer" :["jquery"],

  }

});




define("config", function(){});

