define('dataCaching',['require','exports','module','app','collections/dataCollection','collections/mxpFleetCollection','collections/forecastCollection'],function (require, exports, module) {
  var app = require("app");
  var dataCollection = require("collections/dataCollection");
  var fleetCollection = require("collections/mxpFleetCollection");
  var forecastCollection = require("collections/forecastCollection");

  var caching = module.exports;
  // if(!app.updating) app.loader('show');

  /**
   * List of all collections that are hooked to cacheModel
   */
  var collectionArray = {
    slidesData: dataCollection,
    forecast: forecastCollection,
    mxpFleet: fleetCollection,
  };

  //TODO: check for updates
  caching.checkUpdates = function (device_id, callback) {
    if (!app.localStorage.lastUpdated) {
      app.localStorage.lastUpdated = 0;
    }

    /**
     * TODO: replace $.ajax and $.getJSON with the below code
     */
    app.getCollectionData("slidesData", 900, "", "", function (slidesData) {
      var data = {
        data: slidesData,
      };

      if (
        typeof app.lastPullDataSlides != "undefined" &&
        typeof data.data != "undefined" &&
        data.data &&
        typeof data.data.slides != "undefined" &&
        !_.isEqual(app.lastPullDataSlides, data.data.slides)
      ) {
        app.data = $.extend(true, {}, data.data);
        if (typeof data.data.slides != "undefined") {
          app.lastPullDataSlides = $.extend(true, [], data.data.slides);
        }
        app.localStorage.lastUpdated = app.data.updated_time;
        // app.loader('hide');
        callback({ success: true, updated: true });
      } else if (typeof data.data != "undefined" && data.data) {
        app.data = $.extend(true, {}, data.data);
        if (typeof data.data.slides != "undefined" && typeof app.lastPullDataSlides == 'undefined') {
          app.lastPullDataSlides = $.extend(true, [], data.data.slides);
        }
        // app.loader('hide');
        callback({ success: true, updated: false });
      } else {
        callback({ success: false, updated: false });
      }
    });

    app.getCollectionData("forecast", 900, "", "", function (forecastData) {
      if (forecastData.forecast) {
        app.forecast = forecastData.forecast.txt_forecast.forecastday;
        app.localStorage.forecast = app.forecast;
      } else if (forecastData.forecast10day) {
        app.forecast = forecastData.forecast10day.txt_forecast.forecastday;
        app.localStorage.forecast = app.forecast;
      }
    });
  };

  caching.saveToLocal = function (device_id, callback) {
    if (app.data) {
      var data = JSON.stringify(app.data);
      app.localStorage[device_id + "_data"] = data;

      callback({ success: true });
    }
  };

  caching.getFromLocal = function (device_id, callback) {
    self = this;
    app.data = JSON.parse(app.localStorage[device_id + "_data"]);

    if (app.data) callback({ success: true });

    callback({ success: false });
  };

  /**
   * Cache Model
   */
  app.cacheModel = function (name, data) {
    if (!app.cachedModels[name]) app.cachedModels[name] = {};

    app.cachedModels[name].data = data;
    app.cachedModels[name].lastFetchedDate = new Date();
  };

  app.queryApiEndpoint = function (name, query_params, uniq_id, cb) {
    var params = app.setParam(name, query_params, uniq_id);
    if (params.type == "guest" && params.query === "") {
      if (cb) cb({});
    } else {
      app.queryExternalApiCollection(name, params, uniq_id, function (result) {
        if (cb) cb(result);
      });
    }
  };

  app.setParam = function (name, query_params) {
    var params = {};

    switch (name) {
      case "excursion_today":
      case "excursion_tomorrow":
      case "excursion_all":
      case "excursion_detail":
      case "spa_detail":
        params = {
          type: "v1/services/excursion/availability",
          query: query_params,
        };
        break;
      default:
        params = {
          type: "v1/services/excursion/availability",
          query: query_params,
        };
    }

    return params;
  };

  app.queryExternalApiCollection = function (name, params, uniq_id, cb) {
    if (
      (name == "excursion_today" ||
        name == "excursion_tomorrow" ||
        name == "excursion_all") &&
      params.query === ""
    )
      return false;

    var dataSet;

    if (collectionArray[name]) {
      dataSet = new collectionArray[name]();
    } else {
      dataSet = new collection(params);
    }

    $.when((app.cachePullXHR[name + uniq_id] = dataSet.fetch())).then(
      function (success) {
        if (cb) cb(dataSet.toJSON());
        closeModel();
      },
      function (err) {
        if (name === "newsFeed") {
          const xmlData = toXML(err.responseText);
          xmlData.ok ? cb(xmlData.data) : cb({});
        } else {
          if (cb) cb({});
        }
        closeModel();
      }
    );
    function closeModel() {
      dataSet.reset();
      dataSet.unbind();
      delete dataSet;
    }
  };

  app.abortCachePull = function (names) {
    $.each(names, function (i, val) {
      if (
        typeof app.cachePullXHR[val] != "undefined" &&
        typeof app.cachePullXHR[val].readyState != "undefined"
      ) {
        if (
          app.cachePullXHR[val].readyState > 0 &&
          app.cachePullXHR[val].readyState < 4
        ) {
          app.cachePullXHR[val].abort();
          //console.log('aborting'+val);
        }
      }
    });
  };

  app.checkCachedDataExpires = function (name, maxAgeSeconds, uniq_id) {
    if (!app.cachedData[name + uniq_id]) return true;
    if (!app.cachedData[name + uniq_id].data) return true;
    if (!maxAgeSeconds) {
      return false;
    }
    var time = app.cachedData[name + uniq_id].lastFetchedDate.getTime();
    var time2 = new Date().getTime();
    var diff = (time2 - time) / 1000;
    return diff >= maxAgeSeconds ? true : false;
  };

  app.getCollectionData = function (
    name,
    maxAgeSeconds,
    query_params,
    uniq_id,
    cb
  ) {
    uniq_id = uniq_id ? uniq_id : "";

    if (
      app.cachedData &&
      app.cachedData[name + uniq_id] &&
      app.cachedData[name + uniq_id].data &&
      !app.checkCachedDataExpires(name, maxAgeSeconds, uniq_id)
    ) {
      if (cb) cb(app.cachedData[name + uniq_id].data);
    } else {
      queryServer(maxAgeSeconds, uniq_id);
    }

    function queryServer(maxAgeSeconds) {
      app.queryApiEndpoint(name, query_params, uniq_id, function (result) {
        var data = {};
        var backupCache = null;

        if (result && result.length && result[0] && result[0].data) {
          if (maxAgeSeconds > -1) {
            app.cachedData[name + uniq_id] = {};
            app.cachedData[name + uniq_id].data = result[0].data;
            app.cachedData[name + uniq_id].lastFetchedDate = new Date();
            app.cachedData[name + uniq_id].lastFetchBlank = false;
          }
          data = result[0].data;
        } else if (typeof app.cachedData[name + uniq_id] != "undefined") {
          app.cachedData[name + uniq_id].lastFetchBlank = true;
          backupCache = app.cachedData[name + uniq_id].data;
        }

        if (name === "newsFeed") {
          if (cb) cb(backupCache ? backupCache : result);
        } else {
          if (cb) cb(backupCache ? backupCache : data);
        }
      });
    }
  };
});

