// Kick off the application.

window.metadata = null;
window.showScreenSaver = true;

require(["app", "router", "dataCaching", "utils"], function (app, Router, caching) {
  // Define your master router on the application namespace and trigger all
  // navigation from this instance.
  // app.router = new Router();
  // var connection = app.checkConnection();
  var config = app.config(),
    metadata;
  API = config.api_url;
  app.localStorage.weather = config.weather;
  app.localStorage.forecast = config.forecast;

  var viewportDetails = viewportDetection();

  setViewportMetaTag(viewportDetails);

  /*if(config.debug){
    metadata = app.getMetadata();
  }else{
    if (Player) metadata = Player.getMetadata(); //use this if on server
    else metadata = app.getMetadata();
  }

  if(metadata.DeviceID){*/

  var device_id = 0; //null for now
  var checkDataInterval = null;

  //check for updates
  app.startApp = function (device_id) {
    caching.checkUpdates(device_id, function (result) {
      clearInterval(checkDataInterval);
      if (result.success) {
        //  console.log('playlist availability checking has started');
        if (app.data.slides) {
          app.showPage(["views/sliderController"]);
        } else {
          app.loader("show");
          checkDataInterval = setInterval(function () {
            // console.log('start checking if data is on');
            app.startApp(device_id);
          }, 60000);
        }
      } else {
        app.loader("show");
        checkDataInterval = setInterval(function () {
          // console.log('start checking if data is on');
          app.startApp(device_id);
        }, 60000);
      }
    });
  };

  // }

  setTimeout(function () {
    if (typeof Player != "undefined") {
      Player.closeSplashScreen();
      Player.onMessageReceived.connect(function (object) {
        if (
          typeof object.action != "undefined" &&
          object.action == "settings" &&
          typeof object.vals != "undefined"
        ) {
          if (typeof object.vals["ts"] != "undefined") {
            window.globalTime = timeAPIFormat(object.vals["ts"], "object");
          }
          if (
            typeof object.vals["overOff"] != "undefined" &&
            object.vals["overOff"]
          ) {
            window.showScreenSaver = true;
            if ($("#override_div").css("opacity") != 0) {
              $("#override_div").css({ opacity: 0 });
            }
          }
          if (
            typeof window.metadata != "undefined" &&
            typeof window.metadata["device_id"] != "undefined"
          ) {
            var update_sid = false;
            if (typeof object.vals["sid"] == "undefined") {
              update_sid = true;
            } else if (window.metadata["device_id"] != object.vals["sid"]) {
              update_sid = true;
            }
            if (update_sid) {
              Player.sendMessage({
                type: "screensend",
                screen_id: window.metadata["device_id"] + "",
                attachoverride: true,
              });
            }
          }
        } else if (
          typeof object.action != "undefined" &&
          object.action == "getvalall" &&
          typeof object.vals != "undefined"
        ) {
          if (window.WSpullResponse == false) {
            if (typeof object.vals["settings"] != "undefined") {
              if (typeof object.vals["settings"]["ts"] != "undefined") {
                window.globalTime = timeAPIFormat(
                  object.vals["settings"]["ts"],
                  "object"
                );
              }
            }
            window.WSpullResponse = true;
          }
        } else if (
          typeof object.action != "undefined" &&
          object.action == "overrides" &&
          typeof object.vals != "undefined" &&
          typeof window.metadata != "undefined" &&
          typeof window.metadata["Overrides"] != "undefined"
        ) {
          var show_override = null;
          for (var i = 0; i < object.vals.length; i++) {
            if (
              typeof window.metadata["Overrides"][object.vals[i]] != "undefined"
            ) {
              show_override = window.metadata["Overrides"][object.vals[i]];
            }
          }
          if (show_override) {
            window.showScreenSaver = false;
            if (show_override != window.current_override) {
              $("#override_div").css(
                "background-image",
                "url(" + app.BASE_URL + show_override + ")"
              );
              window.current_override = show_override;
            }
            if ($("#override_div").css("opacity") != 1) {
              $("#override_div").css({ opacity: 1 });
            }
          } else {
            if ($("#override_div").css("opacity") != 0) {
              $("#override_div").css({ opacity: 0 });
            }
          }
        } else if (
          typeof object.action != "undefined" &&
          object.action == "locatedevice"
        ) {
          clearTimeout(window.locatorTimeout);
          $("#locator_div").fadeIn(400);
          window.locatorTimeout = setTimeout(function () {
            $("#locator_div").fadeOut();
          }, 10000);
        }
      });
      window.WSpullResponse = false;
      Player.getMetadata(function (d) {
        window.metadata = d;
        waitForWS();
      });
    } else {
      Backbone.history.start({ pushState: true, root: app.root });
      app.startApp(device_id);
    }
  }, 10000);

  function waitForWS() {
    Player.sendMessage({ type: "getvalall" });
    window.WSpullInterval = setInterval(function () {
      if (window.WSpullResponse) {
        clearInterval(window.WSpullInterval);
        window.WSpullInterval = null;
        Backbone.history.start({ pushState: true, root: app.root });
        app.startApp(device_id);
      } else {
        Player.sendMessage({ type: "getvalall" });
      }
    }, 3000);
    // If no response after 30 seconds, proceed
    setTimeout(function () {
      window.WSpullResponse = true;
    }, 30000);
  }

  // Trigger the initial route and enable HTML5 History API support, set the
  // root folder to '/' by default.  Change in app.js.
});

define("main", function(){});

