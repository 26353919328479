define('collections/forecastCollection',["jquery", "backbone", "app"], function ($, Backbone, app) {
  var forecastModel = Backbone.Model.extend();

  // Creates a new Backbone Collection class object
  var forecastCollection = Backbone.Collection.extend({
    // Tells the Backbone Collection that all of it's models will be of type Model (listed up top as a dependency)
    model: forecastModel,
    url: app.API_URL + "v1/weather/?request=forecast10day",
  });

  // Returns the Model class
  return forecastCollection;
});

