	var viewHelpers = {
	specialChar : function(string){
	   var iChars = "!@#$%^&*()+=[]\\\';,/{}|\":<>?";
	   var detected = 0;
		for (var i = 0; i < string.length; i++) {
			if (iChars.indexOf(string.charAt(i)) != -1) {
				detected++;
			}
		}
		return detected;
	},

	//Loading Message
	/*function loaderOpts(opt) {
		var loaderOpt = {
			text: opt.text === undefined ? '' : opt.text,
			textVisible: opt.textVisible === undefined ? $.mobile.loader.prototype.options.textVisible : opt.textVisible,
			theme: opt.theme === undefined ? 'a' : opt.theme,
			textonly: opt.textonly === undefined ? false : opt.textonly,
			html: opt.html === undefined ? '' : opt.html
		}
		return loaderOpt;
	}*/

	

	cleanJSONstr : function(str) {
		var myJSONString = JSON.stringify(str);
		var myEscapedJSONString = myJSONString.replace(/\\n/g, "\\n").replace(/\\'/g, "\\'");
		return myEscapedJSONString;
	},

	/* Get Latitude Longitude */
	getLatLng : function(searchStr) {
		var geocoder = new google.maps.Geocoder();
		geocoder.geocode({ 'address': searchStr+' US' }, function(results, status) {
	    if (status == google.maps.GeocoderStatus.OK) {
	    	return {
					lat: results[0].geometry.location.lat(),
					lng: results[0].geometry.location.lng()	    	
	    	};
	    } else {
	      return false;
	    }
	  });
	},

	//Format unix timestamp to be readable
	timeFormat : function(time,format) {
		if(time) {
			var dt;
			if(format=='readable' || format == 'custom_month' || format == 'twelvehrtime') {
	  			dt = time;
				if(format == 'custom_month'){
					format = "month";
				}
			} else {
			  dt = new Date(time * 1000);
			}
			
			var year = dt.getUTCFullYear();
			var month = dt.getUTCMonth() + 1;
			var day = dt.getUTCDate();
			var hours = dt.getUTCHours();
			var minutes = dt.getUTCMinutes();
			var seconds = dt.getUTCSeconds();

			if (year < 10) {
				year = '0' + year;
			}
			if (month < 10) {
				month = '0' + month;
			}
			if (day < 10) {
				day = '0' + day;
			}
			if (hours < 10) {
				hours = hours;
			}
			if (minutes < 10) {
				minutes = '0' + minutes;
			}
			if (seconds < 10) {
				seconds = '0' + seconds;
			}
			if( format == 'month' ) {
				return month + "/" + day + "/" + year;
			}
			else if( format =='time' ) {
				return hours + ":" + minutes + ":" + seconds;
			}
			else if( format == 'date clocktime' ) {
				return month + "/" + day + "/" + year + " " + getClockTime( hours, minutes );
			}
                        else if( format == 'twelvehrtime' ) {
                                return viewHelpers.getClockTime( hours, minutes );
                        }
			else if( format == 'readable' ) {
				var m = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
				if(m[dt.getUTCMonth()] == 'undefined' || isNaN(dt.getUTCDate()) === true || isNaN(year) === true ) {
					return '';
				} else {
					return m[dt.getUTCMonth()] + ' ' + dt.getUTCDate() + ', ' + year;
					//return dt.getUTCDate()+' '+m[dt.getUTCMonth()]+' '+year;
				}
			}
			else if(format == 'highlight') {
				return month + '/' + day + '/' + year + ' - ' + hours + ':' + minutes;
			}
			else {
				return year + "/" + month + "/" + day + " " + hours + ":" + minutes + ":" + seconds;
			}
		}
		return false;
	},

	//Format time in Clock Format
	getClockTime : function( hour, minute ) {
	   var ap = "AM";
	   if (hour   > 11) { ap = "PM";             }
	   if (hour   > 12) { hour = hour - 12;      }
	   if (hour   === 0) { hour = 12;             }
	   //if (hour   < 10) { hour   = "0" + hour;   }
	   //if (minute < 10) { minute = "0" + minute; }
	   return hour + ':' + minute + ' ' + ap;
	},

	//Format time in Clock Format
	getClockTimeV2 : function( hour, minute ) {
	   return hour + ':' + minute;
	},

	parseDayAsString : function(day){
		var weekdays = new Array(7);
		weekdays[0] = "Sunday";
		weekdays[1] = "Monday";
		weekdays[2] = "Tuesday";
		weekdays[3] = "Wednesday";
		weekdays[4] = "Thursday";
		weekdays[5] = "Friday";
		weekdays[6] = "Saturday";

		return weekdays[day];
	}
}

var rest_translations = {
	"The Restaurant|Lunch|First Course" : 'Starters',
	"The Restaurant|Dinner|First Course" : 'Starters',
	"Manfredi's|Dinner|First Course" : 'Starters & Soups',
	"Manfredi's|Dinner|Second Course" : 'Pasta & Risotto',
	"World Cafe|Breakfast|Cold Buffet": 'Cold Selection',
	"World Cafe|Breakfast|Breakfast": 'Cold Selection',
	"World Cafe|Breakfast|Bakery": 'From the Bakery',
	"World Cafe|Breakfast|Eggs": 'Hot Selection',
	"World Cafe|Breakfast|Hot Buffet": 'Hot Selection',
	"World Cafe|Breakfast|Side Dishes": 'Hot Selection',
	"World Cafe|Lunch|Cold Buffet": 'Starters',
	"World Cafe|Lunch|Salad Bar": 'Starters',
	"World Cafe|Lunch|Hot Soup": 'Starters',
	"World Cafe|Lunch|Main Course": 'Main Courses & Sides',
	"World Cafe|Lunch|Main Courses": 'Main Courses & Sides',
	"World Cafe|Lunch|NEW": 'Main Courses & Sides',
	"World Cafe|Lunch|New": 'Main Courses & Sides',
	"World Cafe|Lunch|Sandwich\/Pizza": 'Main Courses & Sides',
	"World Cafe|Lunch|Side Dishes": 'Main Courses & Sides',
	"World Cafe|Dinner|Cold Buffet": 'Starters',
	"World Cafe|Dinner|Salad Bar": 'Starters',
	"World Cafe|Dinner|Hot Soup": 'Starters',
	"World Cafe|Dinner|Tapas": 'Starters',
	"World Cafe|Dinner|Main Course": 'Main Courses & Sides',
	"World Cafe|Dinner|Main Courses": 'Main Courses & Sides',
	"World Cafe|Dinner|NEW": 'Main Courses & Sides',
	"World Cafe|Dinner|New": 'Main Courses & Sides',
	"World Cafe|Dinner|Side Dishes": 'Main Courses & Sides',
	"Mamsen\'s|Lunch|Main Course": 'Nordic Buffet',
	"Mamsen\'s|Lunch|Dessert": 'Nordic Buffet',
	"Mamsen\'s|Afternoon Deli|Main Course": 'Coffee and Cake',
	"Mamsen\'s|Afternoon Deli|Dessert": 'Coffee and Cake',
	"Mamsen\'s|Dinner|Main Course": 'Midnight Snack',
	"The Chef\'s Table|Dinner|First Courses": 'First Course',
	"The Chef\'s Table|Dinner|Main Courses": 'Main Course',
	"The Chef\'s Table|Dinner|Desserts": 'Dessert',
};

var rest_sort_order = {
	"World Cafe|Breakfast|Bakery": 0,
	"World Cafe|Breakfast|Beverage": -1
};

function translate_course_items(restaurant_name, meal_type, menu_items) {
	var translated_course_items = [];
	var course_name_pos = {};
	_.each(menu_items, function(menu_item) {
		var key_search = restaurant_name+"|"+meal_type+"|"+menu_item.course_name;
		var course_name = menu_item.course_name;
		if(key_search in rest_translations) {
			course_name = rest_translations[key_search];
		}
		if(course_name in course_name_pos) {
			translated_course_items[course_name_pos[course_name]].dishes = translated_course_items[course_name_pos[course_name]].dishes.concat(menu_item.dishes);
		} else {
			if(key_search in rest_sort_order) {
				if(rest_sort_order[key_search] != -1) {
					translated_course_items.splice( rest_sort_order[key_search], 0, {
						'course_name': course_name,
						'dishes': menu_item.dishes.concat([])
					});					
				}
			} else {
				translated_course_items.push( {
					'course_name': course_name,
					'dishes': menu_item.dishes.concat([])
				});
			}
			course_name_pos[course_name] = translated_course_items.length - 1;
		}
	});
	return translated_course_items;
}

function restaurantOnSlide(slide) {
		var sortedData = [];
		if(slide.data && typeof slide.data.items != 'undefined' && slide.data.items) {
			sortedData = sortFoodMenu(slide.data.items);
		} else {
			slide.data = {};
		}
		slide.data.menu = sortedData.length ? [sortedData[0]] : [];
		var mxp_restaurant_name = "";
		if(slide.data && typeof slide.data.restaurant_name != 'undefined' && slide.data.restaurant_name) {
			mxp_restaurant_name = slide.data.restaurant_name;
		}
		var displayItems = [];
		_.each(slide.data.menu, function(meal) {
			var dining_menus = [];
			slide.data.selectedMealTime = meal.meal_from_time;
			if(typeof meal.menu_items != 'undefined' && meal.menu_items.length && typeof meal.menu_items[0].dishes == 'undefined') {
					dining_menus.push({
					'course_name': '',
					'dishes': meal.menu_items
				});
			} else {
					dining_menus = translate_course_items(mxp_restaurant_name, meal.meal_type, meal.menu_items);
			}
			_.each(dining_menus, function(dimu) {
				 if(dimu.course_name && dining_menus.length > 1) {
					 displayItems.push({ 'type': 'course_name', 'value': dimu.course_name});
				 }
				 _.each(dimu.dishes, function(dimdish) {
					 displayItems.push({ 'type': 'dish', 'value': dimdish});
				 });
			});
		});
		
		slide.data.totalItems = displayItems.length;
		slide.data.displayItems = displayItems;
}

function sortFoodMenu(menuItems) {
	var forDisplay = [];
	var firstDay = 0;
	var firstDayDisplay = [];
	var todayTemp = [];
    var firstDayTemp = [];
	//var today = viewHelpers.timeFormat(new Date(1440633600 * 1000),'custom_month'); //use this to test the sample data.json provided
	var today = viewHelpers.timeFormat(getNowVar(),'custom_month');
     var today_midnight = getDayMidnightUTCfromTime(getNowVar());
     if(menuItems) {
	$.each(menuItems, function(key,item) {
		var itemDate = timeAPIFormat(item.meal_date,'custom_month');

		if(!firstDay) {
		    firstDay = itemDate;
		}
		if(firstDay==itemDate) {
            firstDayTemp.push(item);
            var previous_meal_end_midnight = getDayMidnightUTCfromTime(timeAPIFormat(item.meal_to_time,'object'));
             var milliseconds_diff = timeAPIFormat(item.meal_to_time,'object').getTime() - previous_meal_end_midnight.getTime();
             previous_meal_end = new Date(today_midnight.getTime() + milliseconds_diff);
 			if(previous_meal_end.getTime() >= getNowVar().getTime()){ 
				firstDayDisplay.push(item);
			}
		}
		//console.log(today +' = '+itemDate);

		if(today==itemDate) {
			//push todays items on temp array
			todayTemp.push(item);

			//select meals thats greater than the current time
			//if((item.meal_to_time * 1000) >= (1440673791 * 1000)){ //use this to test the sample data.json provided
			if(timeAPIFormat(item.meal_to_time,'object').getTime() >= getNowVar().getTime()){
				forDisplay.push(item);
			}
		}
	});
	}

	if(!forDisplay.length) {
		forDisplay = todayTemp.length ? [todayTemp[todayTemp.length - 1]] : [];
	}
    
    if(!forDisplay.length) {
        if(firstDayDisplay.length) {
           forDisplay = firstDayDisplay;
        } else {
            forDisplay = firstDayTemp.length ? [firstDayTemp[firstDayTemp.length - 1]] : [];
        }
    }

	//console.log(forDisplay);

	forDisplay.sort(sort_by('meal_from_time', false, parseInt)); //sorted by mealtime

	return forDisplay;
}

function getDayMidnightUTCfromTime(time) {
	var date_var = time;
	date_var.setUTCHours(0,0,0);
	return date_var;
}

var resizeExtensions = ['.jpg', '.jpeg', '.gif', '.png']

function replaceImages(cdnlink, img_width, img_height){
	var replace_hw = "-"+img_width;
	if (typeof img_height != 'undefined') {
		replace_hw += "x"+img_height;
	}
	if(cdnlink) {
		if(resizeExtensions.some(function(v) { return ((cdnlink.toLowerCase()).indexOf(v) > -1); })) {
			return cdnlink.replace('-original', replace_hw);
		} else {
			return cdnlink;
		}
	} else {
		return "";
	}
}

function timeToAPIFormat(time) {
	var year_out = time.getUTCFullYear();
	var month_out = time.getUTCMonth() + 1;
	if(month_out < 10) {
		month_out = '0'+month_out;
	}
	var day_out = time.getUTCDate();
	if(day_out < 10) {
		day_out = '0'+day_out;
	}
	var hour_out = time.getUTCHours();
	if(hour_out < 10) {
		hour_out = '0'+hour_out;
	}
	var minute_out = time.getUTCMinutes();
	if(minute_out < 10) {
		minute_out = '0'+minute_out;
	}
	var seconds_out = time.getUTCSeconds();
	if(seconds_out < 10) {
		seconds_out = '0'+seconds_out;
	}
	return year_out+'-'+month_out+'-'+day_out+' '+hour_out+':'+minute_out+':'+seconds_out;
}

function timeAPIFormat(time, format) {
	var t = time.split(/[- :]/);
	var dt_temp = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
	var dt = new Date(dt_temp.getTime()-(dt_temp.getTimezoneOffset()*60*1000));
	if( format == 'timestamp') {
		return (dt.getTime() / 1000) | 0;
	}
	if( format == 'object') {
		return dt;
	} else {
		return viewHelpers.timeFormat(dt, format);
	}
}


function getNowVar() {
	if(window.globalTime) {
		return new Date(window.globalTime.getTime());
	} else {
		var d = new Date();
		return d;
	}
}

function updateHeader() { 
	  clearTimeout(window.headerTimeout);
	  window.headerTimeout = setTimeout(function() {
	      updateHeader();
		  if(window.display_temp_f) {
			  if(window.display_units == 'imp') {
				window.display_units = 'met';
				$('div.header li h1.weatherTemp').animate({'opacity': 0, 'avoidCSSTransitions': true}, 150, function() {
					if(window.display_temp_c) {
						$('div.header li h1.weatherTemp').html(window.display_temp_c+'&deg;C');
					}
					$('div.header li h1.weatherTemp').css({'opacity': 1});
				});
				$('div.header li span.feelsLike').animate({'opacity': 0, 'avoidCSSTransitions': true}, 150, function() {
					if(window.display_temp_f) {
						$('div.header li span.feelsLike').html(window.display_temp_f+'&deg;F');
					}
					$('div.header li span.feelsLike').css({'opacity': 1});
				});
			  } else {
				window.display_units = 'imp';
				$('div.header li h1.weatherTemp').animate({'opacity': 0, 'avoidCSSTransitions': true}, 150, function() {
					if(window.display_temp_f) {
						$('div.header li h1.weatherTemp').html(window.display_temp_f+'&deg;F');
					}
					$('div.header li h1.weatherTemp').css({'opacity': 1});
				});
				$('div.header li span.feelsLike').animate({'opacity': 0, 'avoidCSSTransitions': true}, 150, function() {
					if(window.display_temp_c) {
						$('div.header li span.feelsLike').html(window.display_temp_c+'&deg;C');
					}
					$('div.header li span.feelsLike').css({'opacity': 1});
				});
			  }
		  }
	  }, 10000);
      var time = getNowVar();
      var hr = time.getUTCHours();
      var min = time.getUTCMinutes();
      var sec = time.getUTCSeconds();
      var ampm = " PM "
      if (hr < 12){
      ampm = " AM "
      }
      if (hr > 12){
      hr -= 12
      }
      if (hr < 10){
      hr = " " + hr
      }
      if (min < 10){
      min = "0" + min
      }
      if (sec < 10){
      sec = "0" + sec
      }
      if(hr == 0) {
	hr = 12;
      }
      $('div.header h1.time').html(hr + ":" + min + "<span style=\"font-size: 18px;\">"+ampm+"</span>");
	  var date = getNowVar();
      var year = date.getUTCFullYear();
      var day = date.getUTCDay();
	  var weekday=new Array("Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday")
      var monthArray = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December")
      $('div.header span.date').html( weekday[day]+', '+monthArray[date.getUTCMonth()] + " " + date.getUTCDate() + ", " + year);
}

/* Clean JSON string */
String.prototype.escapeSpecialChars = function() {
    return this.replace(/\\n/g, "\\n")
               .replace(/\\'/g, "\\'")
               .replace(/\\&/g, "\\&")
               .replace(/\\r/g, "\\r")
               .replace(/\\t/g, "\\t")
               .replace(/\\b/g, "\\b")
               .replace(/\\f/g, "\\f");
};

/* sort array */
// Sort by price high to low
//homes.sort(sort_by('price', true, parseInt));
// Sort by city, case-insensitive, A-Z
//homes.sort(sort_by('city', false, function(a){return a.toUpperCase()}));
var sort_by = function(field, reverse, primer){

	var key = primer ?
		function(x) {return primer(x[field])} :
		function(x) {return x[field]};

	reverse = !reverse ? 1 : -1;

	return function (a, b) {
		return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
	}
};

function viewportDetection() {
	var baseScale = 1;

	var details = {
		scaleDimension: 'width',
		width: null,
		initialScale: baseScale
	};

	if (window.outerHeight > window.outerWidth) {
		details.width = '720';
		details.initialScale = window.outerWidth / 720
	} else {
		details.width = '1280';
		details.initialScale = window.outerWidth / 1280
	}

	return details;
}

function setViewportMetaTag(viewportDetails) {
	document.querySelector('meta[name="viewport"]')
		.setAttribute(
			'content',
			'initial-scale=' + viewportDetails.initialScale + ', user-scalable=no'
		);
}
;
define("utils", function(){});

