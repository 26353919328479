define('views/partials/header',[
  'jquery',
  'underscore',
  'backbone',
  'text!templates/partials/header.html'
], function($,_, Backbone, tmpl){

  var HeaderView = Backbone.View.extend({
      tagName:'li',

    initialize : function(settings, data){
      this.data = data;
      this.settings = settings;
      // console.log(settings);
    },

    render: function(){
      var self = this;
	  window.display_temp_f = '';
	  window.display_temp_c = '';
	  window.display_units = 'imp';
	  if(typeof self.data != 'undefined' && self.data && typeof self.data.current_observation != 'undefined' && typeof self.data.current_observation.temp_f != 'undefined' && !isNaN(self.data.current_observation.temp_f)) {
		window.display_temp_f = Math.round(self.data.current_observation.temp_f);
		window.display_temp_c = Math.round(( window.display_temp_f - 32) * (5/9));
	  }
      var tpl = _.template(tmpl,{data: this.data, settings: this.settings});

      this.$el.html(tpl);
		updateHeader();
      return this;
    }
  
  });

  return HeaderView;
  
});

