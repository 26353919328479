define(
	'views/slides/feedViews/torshavnLounge',[
		"jquery",
		"lodash",
		"backbone",
		"text!templates/feedsTpl/torshavnLounge.html",
		"app",
	], function($, _, Backbone, tmpl, app) {
	
		var View = Backbone.View.extend({
			tagName:'li',

		  attributes: {'class':'feedsTpl'},

			initialize : function(data){
				this.slide = data;
				this.attributes = {"data-interval":data.duration, "data-template":data.template, "data-type":"Torshvn"};
				this.$el.attr(this.attributes);

			},
			
			render : function(){
				var self = this;
				var tor_feed = {
                    "name": "TORSHAVN",
                    "tagline": "Smooth Music. Smooth Pours.",
                    "description": "A lively music venue reminiscent of romantic jazz lounges of 1940s Hollywood, Torshavn is ideal\r\nfor pre-dinner drinks and after-dinner entertainment.",
                    "shows": [
                        {
                            "show_name": "Vintage Armagnacs",
                            "description": "We offer rare vintage Armagnacs from the years 1935-1965. Select one to toast a personal special year: a birthday, marriage, child or grandchild\u2019s birth, retirement or anniversary. Or perhaps a year of other historic significance. Explore Armagnacs and discover the variety of nuances in these extraordinary, limited-quantity brandies from France\u2019s Gascony region.",
                            "schedules": [
                                {
                                    "schedule": "Daily",
                                    "showtimes": [
                                        {
                                            "start": {
                                                "time": "9:00",
                                                "period": "PM"
                                            },
                                            "end": {
                                                "time": "Midnight",
                                                "period": ""
                                            }
                                        }
                                    ]
                                }
                            ],
                            "image": "/app/img/feedsTpl/torshavn-lounge_armagnac-2_384x384.jpg"
                        },
                        {
                            "show_name": "Classic Jazz",
                            "description": "At Torshavn, the lights are low and the music is swinging. Music lovers gather here for the most sophisticated entertainment at sea. Settle in, order your favorite drink and lose yourself to soothing piano melodies, vocal artistry and the passionate strains of jazz. From modern reinterpretations of old standards to traditional favorites, Torshavn provides an intimate club experience like no other.",
                            "schedules": [
                                {
                                    "schedule": "Daily",
                                    "showtimes": [
                                        {
                                            "start": {
                                                "time": "9:00",
                                                "period": "PM"
                                            },
                                            "end": {
                                                "time": "MIDNIGHT"
                                            }
                                        }
                                    ]
                                }
                            ],
                            "image": "/app/img/feedsTpl/torshavn_384x384.jpg"
                        }
                    ],
                    "schedules": [
                        {
                            "schedule": "",
                            "time": "Consult the Viking Daily"
                        }
                    ]
                };
					var html = _.template(tmpl, {slide: tor_feed});
	        self.$el.html(html);

		    return this;
			}

		});
		
		return View;
	}
);
	

