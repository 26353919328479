define(
	'views/slides/weather',[
		"jquery",
		"lodash",
		"backbone",
		"text!templates/weather.html",
		"text!templates/weatherv2.html",
		"utils",
		"config"
	], function($, _, Backbone, tmpl, tmplv2, utils) {
	
		var View = Backbone.View.extend({
			tagName:'li',
		  
		  attributes: {},

			initialize : function(options){

				this.attributes = {"data-interval":options.data.duration, "data-template":options.data.template, "data-type":options.data.asset_type};
				this.$el.attr(this.attributes);

				this.template = options.type ? tmplv2 : tmpl;
			},
			
			render : function(){
				var self = this;

				var data = {};
				data.queryMethod = "GET";

				//call get weather
				this.model.jqmAjax('GetWeather', data, function(result){
				console.log(result);
				var data = _.extend({data: result, date: getNowVar()}, viewHelpers);
				var html = _.template(self.template, data);
					self.$el.html(html);
					WEATHER_SLIDE_ADDED = true;
				});

				return self;
			}
		});
		
		return View;
	}
);
	

