define('app',['require','exports','module','lodash','jquery','backbone'],function (require, exports, module) {
  "use strict";

  var _ = require("lodash");
  var $ = require("jquery");
  var Backbone = require("backbone");

  // Alias the module for easier identification.
  var app = module.exports;

  /**
   * Debug
   */
  app.DEBUG = false;

  /**
   * Init CacheModel
   */
  app.cachedData = {};
  app.cachedModels = {};
  app.cachePullXHR = {};
  app.localStorage = {};

  /**
   * API
   */
  app.BASE_URL = "https://dragonfly.sourcetoadtest.com/"

  app.API_URL = app.BASE_URL + "api/";

  if (app.localStorage.homeDir) app.homeDir = app.localStorage.homeDir;

  // app.db = window.openDatabase("vikingsignageDB", "1.0", "Viking Signage local DB", 100000);

  app.showPage = function (includes, param) {
    var viewParam = param ? param : null;

    require(includes, function (View) {
      var currentView = new View(viewParam);

      $(currentView.el).addClass("pageView");
      currentView.render();

      $(".pageView").hide();
      $("#main").html(currentView.el);

      if (typeof currentView.loaded == "function") {
        currentView.loaded();
      }
    });
  };

  //App methods
  app.back = function () {
    if (app.routesHit > 1) {
      window.history.back();
      app.routesHit--;
    } else {
      app.routesHit = 0;
      app.router.navigate("", { trigger: true, replace: true });
    }
  };

  app.routesHit = 0;

  app.currentModule = [];

  app.unloadModules = function (newModules) {
    var context = require.s.contexts._;
    if (app.currentModule.length) {
      $.each(app.currentModule, function (key, module) {
        if (delete context.defined[module]) delete context.defined[module];

        $("head script[data-requiremodule='" + module + "']").remove();
      });
    }
    app.currentModule = newModules;
  };

  app.fullpageLoader = function (enable, txt) {
    if (enable) {
      var loaderText = txt ? txt : "Loading...";
      $("body").append(
        '<div id="fullpageLoader"><div class="loaderMsg">' +
          loaderText +
          "</div></div>"
      );
    } else {
      if ($("#fullpageLoader").length) {
        $("#fullpageLoader").remove();
      }
    }
  };

  app.loader = function (opt) {
    var showing = $("div.pageload-overlay").hasClass("show");
    // console.log(showing);
    if (opt == "show") {
      if (!showing) $("div.pageload-overlay").addClass("show");
    } else {
      $("div.pageload-overlay").removeClass("show");
    }
  };

  app.getLocalPath = function (remoteUri) {
    // console.log(remoteUri);
    var url = remoteUri,
      filename;

    if (
      !remoteUri.includes("http://") &&
      !remoteUri.includes("https://") &&
      remoteUri.includes("cdn")
    ) {
      url = app.BASE_URL + remoteUri;
    }

    return url;
  };

  app.fileExists = function (file_url) {
    var http = new XMLHttpRequest();

    http.open("HEAD", file_url, false);
    http.send();

    return http.status != 404;
  };

  app.assign = function (selector, view) {
    var selectors;

    if (_.isObject(selector)) {
      selectors = selector;
    } else {
      selectors = {};
      selectors[selector] = view;
    }

    if (!selectors) return;

    _.each(
      selectors,
      function (view, selector) {
        view.setElement($(selector)).render();
      },
      this
    );
  };

  app.getMetadata = function () {
    var http = new XMLHttpRequest();

    http.open("GET", "data/owstplayer-metadata.json", false);
    http.send();

    return $.parseJSON(http.response);
  };

  app.config = function () {
    var http = new XMLHttpRequest();

    http.open("GET", "config.json", false);
    http.send();

    return $.parseJSON(http.response);
  };

  var weatherIconPath = "app/img/weather/";

  app.weatherIcons = {
    chanceflurries: weatherIconPath + "chanceflurries-day.png",
    chancerain: weatherIconPath + "chancerain-day.png",
    chancesleet: weatherIconPath + "chancesleet-day.png",
    chancesnow: weatherIconPath + "chancesnow-day.png",
    snow: weatherIconPath + "snow-day.png",
    chancetstorms: weatherIconPath + "chancestorms-day.png",
    clear: weatherIconPath + "clear-day.png",
    cloudy: weatherIconPath + "cloudy-day.png",
    flurries: weatherIconPath + "flurries-day.png",
    fog: weatherIconPath + "fog-day.png",
    hazy: weatherIconPath + "hazy-day.png",
    mostlycloudy: weatherIconPath + "mostlycloudy-day.png",
    mostlysunny: weatherIconPath + "mostlysunny-day.png",
    partlycloudy: weatherIconPath + "partlycloudy-day.png",
    partlysunny: weatherIconPath + "partylysunny-day.png",
    sleet: weatherIconPath + "sleet-day.png",
    rain: weatherIconPath + "rain-day.png",
    sunny: weatherIconPath + "sunny-day.png",
    tstorms: weatherIconPath + "tstorms-day.png",
    nt_chanceflurries: weatherIconPath + "chanceflurries-night.png",
    nt_chancerain: weatherIconPath + "chancerain-night.png",
    nt_chancesleet: weatherIconPath + "chancesleet-night.png",
    nt_chancesnow: weatherIconPath + "chancesnow-night.png",
    nt_snow: weatherIconPath + "snow-night.png",
    nt_chancetstorms: weatherIconPath + "chance-storms-night.png",
    nt_clear: weatherIconPath + "clear-night.png",
    nt_cloudy: weatherIconPath + "cloudy-night.png",
    nt_flurries: weatherIconPath + "flurries-night.png",
    nt_fog: weatherIconPath + "fog-night.png",
    nt_hazy: weatherIconPath + "hazy-night.png",
    nt_mostlycloudy: weatherIconPath + "mostlycloudy-night.png",
    nt_mostlysunny: weatherIconPath + "mostlysunny-night.png",
    nt_partlycloudy: weatherIconPath + "partlycloudy-night.png",
    nt_partlysunny: weatherIconPath + "partlysunny-night.png",
    nt_sleet: weatherIconPath + "sleet-night.png",
    nt_rain: weatherIconPath + "rain-night.png",
    nt_sunny: weatherIconPath + "sunny-night.png",
    nt_tstorms: weatherIconPath + "tstorms-night.png",
  };

  app.get12HClockTime = function (hour, minute) {
    var ap = "AM";
    if (hour > 11) {
      ap = "PM";
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    if (hour === 0) {
      hour = 12;
    }
    //if (hour   < 10) { hour   = "0" + hour;   }
    if (minute < 10) {
      minute = "0" + minute;
    }
    return hour + ":" + minute + " " + ap;
  };

  /**
   * disable all key events
   */
  window.addEventListener(
    "keydown",
    function (e) {
      // space and arrow keys
      // if([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
      //}
    },
    false
  );

  // The root path to run the application through.
  exports.root = "/";
});

