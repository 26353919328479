define('router',["jquery", "backbone", "app"], function($, Backbone, App) {

  var appRouter = Backbone.Router.extend({
    initialize: function() {
      
      Backbone.history.on('route', function() { 
        App.routesHit++;
      }, this);
      this.showPage(["views/sliderController"]);
      
      this.appendEvents();
      setTimeout(function() {
           if (Player) Player.closeSplashScreen();
      }, 4000);
    },
    
    routes: {
      "": "slider",
      "subpage/:id": "subpage"
    },
      
    slider: function() {
      console.log('slider was called');
      this.showPage(["views/sliderController"]);
    },
    
    subpage: function(id) {
      this.showPage(["views/subpage"], { id: id });
    },
    
    showPage: function(includes,param) {
      var viewParam = param ? param : null;
      
      require(includes, function(View) {
        this.currentView = new View(viewParam);
        
        $(this.currentView.el).addClass('pageView');
        this.currentView.render();

        $('.pageView').hide();
        $('#main').html(this.currentView.el);

        if(typeof this.currentView.loaded=='function') {
          this.currentView.loaded();
        }
      });
    },
    
    appendEvents: function() {    
      $(document).on('pagehide','div.pageView',function(e) {
        $(e.currentTarget).remove();
      });
            
      $(document).on('mousedown', '.cta', function() {
        $(this).addClass('tapped');
      }).on('mouseup', '.cta', function() {
        $(this).removeClass('tapped');
      });
            
      //Redirect to home
      $(document).on('click','.homeBtn', function() {
        App.router.navigate('', { trigger: true });
      });      
      
      //Tigger back button
      $(document).on('click','.backBtn',function() {
        App.back();
      });
    }

  });
  
  return appRouter;
});

