define(
    'views/slides/feedViews/restaurantMenu',[
        "jquery",
        "lodash",
        "backbone",
        "text!templates/feedsTpl/restaurantMenu.html",
        "app",
    ], function($, _, Backbone, tmpl, app) {

        var View = Backbone.View.extend({
            tagName:'li',

            attributes: {'class':'feedsTpl'},

            initialize : function(data){
                this.slide = data;
                this.attributes = {"data-interval":data.duration, "data-template":data.template, "data-type":data.data.type};
                this.$el.attr(this.attributes);

            },

            render : function(){
                var self = this;

				var previous_page_position = 0;
				var showItems = [];
				if(self.slide.data.currentPage > 1) {
					var previous_course = null;
					previous_page_position = ((self.slide.data.currentPage-1) * 24);
					for(var icourse=0; icourse<previous_page_position; icourse++) {
						if(self.slide.data.displayItems[icourse].type == 'course_name') {
							previous_course = {'type': 'course_name', value: self.slide.data.displayItems[icourse].value };
							if(icourse != previous_page_position - 1) {
								previous_course.value += ' (cont.)';
							}
						} 
					}
					if(typeof self.slide.data.displayItems[previous_page_position] != 'undefined' && self.slide.data.displayItems[previous_page_position].type != 'course_name') {
						showItems.push(previous_course);
					}
				}
				
                var num_after_course = 0;
				
				for(var iitem=previous_page_position;iitem<(self.slide.data.currentPage*24);iitem++) {
					if(typeof self.slide.data.displayItems[iitem] != 'undefined') {
						showItems.push(self.slide.data.displayItems[iitem]);
                        if(self.slide.data.displayItems[iitem].type == 'course_name') {
                            num_after_course = 0;
                        } else {
                            num_after_course++;
                        }
					}
				}
                
                // Fill in to be continued course with next one to avoid empty spot
                
                if((num_after_course % 2) == 1 && typeof self.slide.data.displayItems[iitem] != 'undefined' && self.slide.data.displayItems[iitem].type != 'course_name') {
                    showItems.push(self.slide.data.displayItems[iitem]);
                }
				
				self.slide.data.showItems = showItems;
                var html = _.template(tmpl, {slide: self.slide.data});
                // console.log(self.slide.data);

                self.$el.html(html).addClass('restoMenuSlide');

                return this;
            },

        });

        return View;
    }
);


