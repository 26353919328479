define(
	'views/slides/image',[
		"jquery",
		"lodash",
		"backbone",
		"text!templates/image.html",
	], function($, _, Backbone, tmpl) {
	
		var View = Backbone.View.extend({
			tagName:'li',

		  attributes: {},

			initialize : function(data){
				this.slide = data;
				this.attributes = {"data-interval":data.duration, "data-template":data.template, "data-type":data.data.type};
				this.$el.attr(this.attributes);

			},

			
			render : function(){
				var self = this;
					var html = _.template(tmpl, {slide: self.slide});
	        self.$el.html(html);

		    return this;
			},


		});
		
		return View;
	}
);
	

